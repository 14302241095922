import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Header from '../components/header'
import Footer from "./footer";
import SiteConfig from "../../config/SiteConfig.json";
import ScrollUpButton from "react-scroll-up-button";
import { navigate } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";

const Layout = (props) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          env
        }
      }
    }
  `)

  let { site: {siteMetadata: {env}} } = data;
  
  const [urlLang, setUrlLang] = useState('')

  useEffect(() => {
    let url_lang = getRedirectLanguage();
    setUrlLang(url_lang)
    if(url_lang !== '/' && window.location.pathname.length < 5){
      navigate(`/${url_lang}/`, { replace: true });
    }
  }, [])

  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "/";
    }
    let navLang = 
    navigator && navigator.language && navigator.language.split("-")[0];
    
    const lang = window.localStorage.getItem('lang') || 
    navLang;
    if (!lang) return "/";

    switch (lang) {
      case "de":
        return "de";
      case "ru":
        return "ru";
      case "es":
        return "es";
      case "pt":
        return "pt";
      case "fr":
        return "fr";
      case "ja":
        return "ja";
      case "it":
        return "it";
      case "cs":
        return "cs";
      case "el":
        return "el";
      case "fi":
        return "fi";
      case "hi":
        return "hi";
      case "hr":
        return "hr";
      case "hu":
        return "hu";
      case "id":
        return "id";
      case "ko":
        return "ko";
      case "nb":
        return "nb";
      case "nl":
        return "nl";
      case "pl":
        return "pl";
      case "tr":
        return "tr";
      case "he":
        return "he";
      case "ar":
        return "ar";
      case "zh":
        return "zh";
      default:
        return "/";
    }
  };


  let id = "body";
  if (SiteConfig.brand === "BlueMail" || SiteConfig.brand === "BlueMail+" || SiteConfig.brand === "BlueChat") {
    id = "body_bm";
  }

  return (
    urlLang && <div>
      {/* <Header lang={props.lang} json={props.json} activeTab={props.activeTab} searchHide={props.searchHide} /> */}
      <Helmet>
        {/* {env && <meta name="robots" content="noindex" />} */}
      </Helmet>
      <ScrollUpButton
        style={{
          outline: "none",
          borderRadius: "10px",
          opacity: "0.1"
        }}
      />
      <div
        id={id}
        style={{ backgroundColor: SiteConfig.content.body.backgroundColor }}
      >
        {props.children}
      </div>
      <Footer lang={props.lang} />
    </div>
  );

}

export default Layout;
