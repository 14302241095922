import React, { Component } from "react";
import SiteConfig from "../../../../config/SiteConfig.json";
import _ from "lodash";
import { Link } from "gatsby";

// const toggle = (category, posts, categoryMeta, index) => {
//   let { activeTab } = this.state;
//   if (activeTab !== category) {
//     this.setState({ activeTab: category, posts: posts, categoryIndex: index });
//   }
//   navigate(categoryMeta);
// };

class sideDrawer extends Component {

  categoryItems = () => {
    let { lang, json, activeTab } = this.props;

    let categoryMeta;
    if (lang === "en") {
      return json.map((jsonPost, i) => {
        categoryMeta = `/category/${_.toLower(jsonPost.category)
          .split(" ")
          .join("-")}`;
        return (
          <Link to={categoryMeta} key={i} className={jsonPost.category === activeTab ? "sd-active-tab" : ""} >
            <li>{jsonPost.category.replace('Amp', '&')}</li>
          </Link>
        );
      });
    } else {
      return json.map((jsonPost, i) => {
        categoryMeta = `/${lang}/category/${_.toLower(jsonPost.category)
          .split(" ")
          .join("-")}`;
        return (
          <Link to={categoryMeta} key={i} className={jsonPost.translatedCategory === activeTab ? "sd-active-tab" : ""} >
            <li>{jsonPost.translatedCategory}</li>
          </Link>
        );
      });
    }
  };

  render() {
    let {
      show,
      drawerToggleClickHandler,
      lang,
      handleMobileChange,
      json
    } = this.props;

    let drawerClasses = "side-drawer";
    if (show) {
      drawerClasses = "side-drawer open";
    }
    let menu = SiteConfig.content.header.menu;

    return (
      <div>
      <nav className={drawerClasses}>
        <ul style={{ padding: "0" }}>
          {menu.map(item => (
            <li key={item.href}>
              <a onClick={drawerToggleClickHandler} href={item.href}>
                {item.name}
              </a>
            </li>
          ))}
          <li>
            <div className="select-wrapper">
              <select name="select" value={lang} onChange={handleMobileChange}>
                <option value="en">English </option>
                <option value="de">Deutsch‎</option>
                <option value="ru">Русский</option>
                <option value="pt">Português</option>
                <option value="it">Italiano‎</option>
                <option value="es">Español‎</option>
                <option value="ja">日本語‎</option>
                <option value="fr">Français</option>
                <option value="cs">Český</option>
                <option value="el">Ελληνικά</option>
                <option value="fi">Finnish Suomalainen</option>
                <option value="hi">हिंदी</option>
                <option value="hr">Hrvatski</option>
                <option value="hu">Magyar</option>
                <option value="id">Indonesia</option>
                <option value="ko">한국어</option>
                <option value="nb">Norsk</option>
                <option value="he">Hebrew</option>
                <option value="ar">Arabic</option>
                <option value="zh">中文</option>
              </select>
              {/* <div style={{color: '#fff', maxHeight: '20px'}}>&#9660;</div> */}
            </div>
          </li>
          {json && this.categoryItems()}

            {SiteConfig.brand === 'BlueMail' && <li>
            <hr style={{marginTop: '0.3rem', marginBottom: '0', background: 'rgba(249,249,249,0.6)'}}/>
            </li>}
          
            { SiteConfig.brand === 'BlueMail' &&  <a href="mailto:support@bluemail.me">
              <li>
              Contact Support
              </li>
            </a>}
            {SiteConfig.brand === 'BlueMail' && <a href="mailto:sales@bluemail.me">
              <li>
              Contact Corporate
              </li>
            </a>}
        </ul>
      </nav>
      </div>
    );
  }
}

export default sideDrawer;
