import React from 'react';
import { Jumbotron } from 'reactstrap';
import SiteConfig from '../../config/SiteConfig.json';
import Dropdown from './drop-down/dropdown'

let year = new Date()

const Footer = (props) => (
      <Jumbotron id="footer" style={{backgroundColor: SiteConfig.content.footer.style.backgroundColor}}>
        <div style={{width: '100%', maxWidth: '1040px', display: 'flex', alignItems: 'center', padding: '0 10px'}}>
          <a href={SiteConfig.seo.copyrightLink} style={{color: '#c2dbee', flex: '1'}}>© {year.getFullYear()}{SiteConfig.seo.copyright}</a>
          {/* <div style={{flex: '1', display: 'flex', justifyContent: 'center'}}>
            <Dropdown
              lang={props.lang}
            />
          </div> */}
          
          <div style={{flex: '1'}}/>
        </div>
      </Jumbotron>
)


export default Footer