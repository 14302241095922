import React, { Component } from 'react';
import SiteConfig from '../../../config/SiteConfig.json';
import {
  en,
  es,
  de,
  ru,
  pt,
  ja,
  fr,
  it,
  cs,
  el,
  fi,
  hi,
  hr,
  hu,
  id,
  ko,
  nb,
  nl,
  pl,
  tr,
  he,
  ar,
  zh
} from "../../../languages/layouts";
import { navigate } from "gatsby";
import Dropdown from "react-dropdown";

let year = new Date()
class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: "en",
    };
  }

  componentDidMount() {
    let lang = this.props.lang;
    if (this.props.lang) {
      this.setState({ lang });
    }
    if (lang === "es") {
      this.setState({ text: es });
    } else if (lang === "de") {
      this.setState({ text: de });
    } else if (lang === "ru") {
      this.setState({ text: ru });
    } else if (lang === "pt") {
      this.setState({ text: pt });
    } else if (lang === "ja") {
      this.setState({ text: ja });
    } else if (lang === "fr") {
      this.setState({ text: fr });
    } else if (lang === "it") {
      this.setState({ text: it });
    } else if (lang === "cs") {
      this.setState({ text: cs });
    } else if (lang === "el") {
      this.setState({ text: el });
    } else if (lang === "fi") {
      this.setState({ text: fi });
    } else if (lang === "hi") {
      this.setState({ text: hi });
    } else if (lang === "hr") {
      this.setState({ text: hr });
    } else if (lang === "hu") {
      this.setState({ text: hu });
    } else if (lang === "id") {
      this.setState({ text: id });
    } else if (lang === "ko") {
      this.setState({ text: ko });
    } else if (lang === "nb") {
      this.setState({ text: nb });
    } else if (lang === "nl") {
      this.setState({ text: nl });
    } else if (lang === "pl") {
      this.setState({ text: pl });
    } else if (lang === "tr") {
      this.setState({ text: tr });
    } else if (lang === "he") {
      this.setState({ text: he });
    } else if (lang === "ar") {
      this.setState({ text: ar });
    }else if (lang === "zh") {
      this.setState({ text: zh });
    } else {
      this.setState({ text: en });
    }
  }

  handleChange = (e) => {
    let target = e.value;
    let currentLang = this.state.lang;
    let currentPathName = window.location.pathname;
    let newPath;

    if (this.props.path) {
      newPath = this.props.path.slice(0, -2);
      newPath = `${newPath}${target}`;
      window.location.replace(`${SiteConfig.url}/search${newPath}`);
    } else {
      if (currentPathName === "/" || currentPathName.length <= 4) {
        if (target !== "en") {
          newPath = `/${target}/`;
        } else {
          newPath = "/";
        }
      } else if (currentLang === "en" && currentPathName.length > 4) {
        if (target !== "en") {
          newPath = `/${target}${currentPathName}`;
        }
      } else {
        if (target !== "en") {
          newPath = currentPathName.replace(`/${currentLang}/`, `/${target}/`);
        } else {
          newPath = "/" + currentPathName.substring(4);
        }
      }
    }
    if(newPath === "/"){
      window.localStorage.setItem('lang', "en")
    }else{
      window.localStorage.setItem('lang', newPath.substring(1,3))
    }
    navigate(newPath);
  }

  render(){
    let lang = this.state.lang;
    const options = [
      { value: "en", label: "English" },
      { value: "de", label: "Deutsch‎" },
      { value: "ru", label: "Русский" },
      { value: "pt", label: "Português" },
      { value: "it", label: "Italiano‎" },
      { value: "es", label: "Español‎" },
      { value: "ja", label: "日本語‎" },
      { value: "fr", label: "Français" },
      { value: "tr", label: "Türk" },
      { value: "pl", label: "Polskie" },
      { value: "nl", label: "Dutch" },
      { value: "cs", label: "Český" },
      { value: "el", label: "Ελληνικά" },
      { value: "fi", label: "Finnish Suomalainen" },
      { value: "hi", label: "हिंदी" },
      { value: "hr", label: "Hrvatski" },
      { value: "hu", label: "Magyar" },
      { value: "id", label: "Indonesia" },
      { value: "ko", label: "한국어" },
      { value: "nb", label: "Norsk" },
      { value: "he", label: "Hebrew" },
      { value: "ar", label: "Arabic" },
      { value: "zh", label: "中文" }
    ];
    return (
        <Dropdown
            options={options}
            value={lang}
            onChange={this.handleChange}
        />
    )
  }
}

export default Footer